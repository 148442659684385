import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  favoritList: any = new BehaviorSubject<Array<any>>([]);
  countForCounter: any = new BehaviorSubject<number>(0);
  counter: any = new BehaviorSubject<number>(0);
  summary: any = new BehaviorSubject<number>(0);
  cartList: any = new BehaviorSubject<Array<any>>([]);
  swipeList: any = new BehaviorSubject<Array<any>>([]);
  token: any;

  constructor(
    private http: HttpClient,
  ) { }

  // Favorit list //
  getFavoritList(value) {
    this.favoritList.next(value);
  }

  // total quantity for all product //
  changeCounter(num) {
    this.counter.next(num);
  }

  // total summa for all product //
  changeSumary(num) {
    this.summary.next(num);
  }

  // changing number in the counter component //
  changeCountForCounter(num) {
    this.countForCounter.next(num);
  }

  // changing bucket list //
  getCartList(value) {
    this.cartList.next(value);
  }

  // changing bucket list //
  setSwipeList(value) {
    this.swipeList.next(value);
  }

  // get catalogue info
  getCatalogAllTopCategories() {
    return this.http.get(`sub_cat`).pipe(data => data);
  }

  getCatalogSomeCategoriesWithFilters(category) {
    return this.http.get(`sub_cat?category=${category}&`).pipe(data => data);
  }

  applyPromocode(body: { promocode: string, phone: string, total: number }) {
    return this.http.post(`app_apply_promocode`, body).pipe(data => data);
  }

  checkCourierLateBonus(body: { phone: string }) {
    return this.http.post(`courier_late_bonus`, body).pipe(data => data);
  }
}
